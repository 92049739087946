import useAuth from './hooks/useAuth';
import { MainRouter } from './routes/Router';
import { Helmet } from 'react-helmet-async';
import './locales/i18n';
import i18n from 'i18next';
import { SplashScreen } from '@dbel/react-commons/components';
import { sentryConfig } from './config';
import { BITBUCKET_COMMIT } from './version';
import {
  browserTracingIntegration,
  init,
  moduleMetadataIntegration,
} from '@sentry/react';

init({
  autoSessionTracking: true,
  dsn: sentryConfig.baseUrl,
  dist: BITBUCKET_COMMIT,
  debug: false,
  // debug: !import.meta.env.PROD,1
  integrations: [moduleMetadataIntegration(), browserTracingIntegration()],
  environment: sentryConfig.env,
  tracesSampleRate: sentryConfig.sampleRate,
});

const App = () => {
  const auth = useAuth();
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}
        defaultTitle='dBEL Architect'
        titleTemplate='dBEL Architect - %s'
      />
      {auth.isInitialized ? <MainRouter /> : <SplashScreen />}
    </>
  );
};

export default App;
