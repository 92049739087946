import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  useGetAccountByIdQuery,
  useGetOrganistationsForUserQuery,
  dBelApi,
  useGetProductsQuery,
} from '@dbel/react-commons/api';
import { useDebug } from '@dbel/react-commons/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainMenuSection } from './components/MainMenuSection';
import { SubMenuEntry } from './components/SubMenuEntry';
import {
  PERMISSIONS_READ_DBEL_ACCOUNTS,
  PERMISSIONS_WRITE_ACCOUNT,
} from '../components/AuthGuard';
import useAuth from '../hooks/useAuth';
import { useDispatch } from '../store';
import { BITBUCKET_COMMIT } from '../version';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AppRegister } from '../AppRegister';
import { ProjectType } from '@dbel/shared/types';
import { DBelLogoSvg } from '@dbel/react-commons/assets';
import SettingsIcon from '@mui/icons-material/Settings';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
export const SideMenu = () => {
  const { t } = useTranslation();
  const debug = useDebug();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user, getTokenForOrganization } = useAuth();

  // we use the user Account name for now
  const { data: myAccount } = useGetAccountByIdQuery(
    user?.accountKey ?? skipToken
  );
  const { data: myAccounts } = useGetOrganistationsForUserQuery(
    user?.id ?? skipToken
  );

  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (index: number) => {
    if (!myAccounts || !myAccount) return;
    if (myAccounts[index].accountKey === myAccount.accountKey) return;
    setAnchorEl(null);

    await getTokenForOrganization(myAccounts[index].organizationId);

    dispatch(dBelApi.util.invalidateTags(['Project']));
    dispatch(dBelApi.util.invalidateTags(['Subscription']));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const canReadAccount = user?.permissions?.includes(PERMISSIONS_READ_ACCOUNT);
  const canReadDBEL = user?.permissions?.includes(
    PERMISSIONS_READ_DBEL_ACCOUNTS
  );

  // const canReadAccount = user?.permissions?.includes(PERMISSIONS_READ_ACCOUNT);
  const canWriteAccount = user?.permissions?.includes(
    PERMISSIONS_WRITE_ACCOUNT
  );

  const { data: products } = useGetProductsQuery();

  const mappedProductKeys = products?.map((item) => item.product.key) ?? [];

  return (
    <>
      <DBelLogoSvg sx={{ fontSize: 70, ml: 2 }} />
      <MainMenuSection title={t('pages.menus.sideMenu.sections.workspace')}>
        {myAccounts && myAccount && (
          <>
            <ListItemButton
              onClick={handleClickListItem}
              sx={{ flexFlow: 'row-reverse' }}
            >
              <ListItemIcon>
                <UnfoldMoreIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {myAccount?.displayName ?? ''}
                </Typography>
              </ListItemText>
            </ListItemButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              variant='selectedMenu'
              disablePortal
            >
              {myAccounts.map((account, index) => (
                <MenuItem
                  key={index + '' + account.accountKey}
                  disabled={!account.active}
                  selected={account.accountKey === myAccount.accountKey}
                  onClick={() => handleMenuItemClick(index)}
                >
                  {account.displayName ?? ''}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        <SubMenuEntry
          title={t('pages.menus.sideMenu.workspace.manageWorkspaceTitle')}
          navTo='/account'
          icon={<SettingsIcon />}
        />
        {false && (
          <SubMenuEntry
            title={t('pages.menus.sideMenu.workspace.manageSubscriptionsTitle')}
            navTo='/subscriptions'
            icon={<WysiwygIcon />}
          />
        )}
        {canReadDBEL && (
          <SubMenuEntry
            title={t(
              'pages.menus.sideMenu.administration.nav.platformAdministration'
            )}
            navTo='/admin'
            icon={<WysiwygIcon />}
          />
        )}
      </MainMenuSection>
      <Divider />

      {Object.keys(AppRegister).map((key) => {
        const app = AppRegister[key as ProjectType];
        if (mappedProductKeys.includes(app.product))
          return (
            <Box key={`${app.name}`}>
              <MainMenuSection
                title={t(`pages.menus.applications.${key}.title`)}
                key={`${app.name}_me`}
              >
                {app.intents.map((intent) => (
                  <SubMenuEntry
                    key={`${intent.name}_${app.name}`}
                    title={t(`pages.menus.applications.${intent.name}`)}
                    navTo={`app/${app.path}/${intent.entryPoint}`}
                    icon={intent.icon}
                  />
                ))}
              </MainMenuSection>
              <Divider key={`${app.name}_dib`} />
            </Box>
          );
      })}
      {canWriteAccount && (
        <MainMenuSection title={t('pages.menus.sideMenu.sections.database')}>
          <SubMenuEntry
            title={t('pages.menus.sideMenu.database.nav.soundsources')}
            navTo='/inventory/soundsources'
            icon={<VolumeUpIcon />}
          />
        </MainMenuSection>
      )}
      <MainMenuSection title={t('pages.menus.sideMenu.sections.help')}>
        <SubMenuEntry
          title={t('pages.menus.sideMenu.help.nav.contact')}
          navTo='mailto:bundschuh@woelfel.de'
        />
      </MainMenuSection>

      {debug.enabled && (
        <>
          <Divider />
          <Typography sx={{ fontSize: 10, color: 'gray', opacity: 0.4, ml: 2 }}>
            {BITBUCKET_COMMIT}
          </Typography>
        </>
      )}
    </>
  );
};
