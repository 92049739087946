import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AllDBelPagesLayout } from '../pages/AllDBelPagesLayout';
import { AllPagesLayout } from '../pages/AllPagesLayout';
import { AllSignUpPagesLayout } from '../pages/signup/AllSignUpPagesLayout';
import { getDBelApiCredentials } from '@dbel/react-commons/api';
import AuthGuard from '../components/AuthGuard';
import { AllErrorPagesLayout, Loadable } from '@dbel/react-commons/components';
import i18n from 'i18next';
import { AppRoutes } from './AppRoutes';
import { WidgetRoutes } from './WidgetRoutes';
import { DBelInventory, DBelOrganisations } from './loadables';

// project pages are internal
const AccountDetailPage = Loadable(lazy(() => import('../pages/account/AccountDetailPage')));
const CreatingAccountPage = Loadable(lazy(() => import('../pages/signup/CreatingAccountPage')));
const CreateAccountPage = Loadable(lazy(() => import('../pages/signup/CreateAccountPage')));

// error pages in shared
const AuthorizationRequired = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.AuthorizationRequired,
    }))
  )
);
const ServerError = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.ServerError,
    }))
  )
);
const PaymentRequired = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.PaymentRequired,
    }))
  )
);
const Forbidden = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.Forbidden,
    }))
  )
);
const NotFound = Loadable(
  lazy(() =>
    import('@dbel/react-commons/components/error').then((module) => ({
      default: module.NotFound,
    }))
  )
);

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="widget/*" element={<WidgetRoutes />} />
      <Route element={<AllPagesLayout />}>
        <Route
          path="/"
          element={
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          }
        >
          <Route path="/" element={<AllDBelPagesLayout />}>
            <Route path="account/*" element={<AccountDetailPage />} />
            <Route path="app/*" element={<AppRoutes />} />
            <Route
              path="inventory/*"
              element={<DBelInventory apiCredentials={getDBelApiCredentials()} language={i18n.language} />}
            />
            <Route
              path="admin/*"
              element={<DBelOrganisations apiCredentials={getDBelApiCredentials()} language={i18n.language} />}
            />
          </Route>
          <Route element={<AllSignUpPagesLayout />}>
            <Route path="signup" />
            <Route path="createAccount" element={<CreateAccountPage />} />
            <Route path="creatingAccount" element={<CreatingAccountPage />} />
          </Route>
        </Route>

        <Route path="authentication/login" element={<AuthGuard />} />

        <Route element={<AllErrorPagesLayout />}>
          <Route path="401" element={<AuthorizationRequired />} />
          <Route path="402" element={<PaymentRequired />} />
          <Route path="403" element={<Forbidden />} />
          <Route path="404" element={<NotFound />} />
          <Route path="500" element={<ServerError />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};
