import { PropsWithChildren, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { SplashScreen } from '@dbel/react-commons/components';

export const PERMISSIONS_READ_DBEL_ACCOUNTS = 'read:dbel-accounts';
export const PERMISSIONS_WRITE_DBEL_ACCOUNTS = 'write:dbel-accounts';
export const PERMISSIONS_READ_ACCOUNT = 'read:account';
export const PERMISSIONS_WRITE_ACCOUNT = 'write:account';

const SIGNUP_ROUTE = 'signup';
const CREATE_ACCOUNT_ROUTE = 'createAccount';

const AuthGuard = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [searchParams] = useSearchParams(window.location.search);

  // if user is logged out show splashscreen, this prevents further rendereing of the tree.
  if (auth.authStatus === 'LOGOUT') {
    return <SplashScreen />;
  }

  console.log('auth.user.token', auth?.user?.token);

  // check auth
  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    // if user has org -> login with org
    const invitation = searchParams.get('invitation');
    const organization = searchParams.get('organization');
    const organizationName = searchParams.get('organization_name');

    if (invitation !== null && organization !== null && organizationName !== null) {
      auth.loginWithRedirect({
        authorizationParams: { organization, invitation },
      });
      return <></>;
    }
    // filter out the signup route -> redirect to signup
    if (location.pathname === `/${SIGNUP_ROUTE}`) {
      auth.loginWithRedirect({
        authorizationParams: { prompt: 'login', screen_hint: 'signup' },
      });
      return <></>;
    }

    // normal login
    console.log('hej');
    auth.loginWithRedirect({});
    return <></>;
  }

  if (location.pathname === `/${SIGNUP_ROUTE}` && auth.accountStatus === 'ACTIVE') {
    return <Navigate to="404" />;
  }
  // if user doesn't have an organisation -> route to create account
  if (auth.accountStatus === 'NONE' && location.pathname !== `/${CREATE_ACCOUNT_ROUTE}`) {
    return <Navigate to={CREATE_ACCOUNT_ROUTE} />;
  }

  // Checks the permissions for /admin
  if (location.pathname.includes('admin')) {
    if (!auth.user?.permissions?.includes(PERMISSIONS_READ_DBEL_ACCOUNTS)) {
      return <Navigate to="401" />;
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
