import { styled } from '@mui/material/styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TopMenu } from '../menus/TopMenu';
import { useGetProductsQuery } from '@dbel/react-commons/api';
import { availableApps } from '../AppRegister';
import { useEffect, useState } from 'react';
import { SplashScreen } from '@dbel/react-commons/components';

export const MAIN_MENU_HEIGHT = 48;

const Header = styled('header')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal - 1,
  })
);

const Main = styled('div')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: `${MAIN_MENU_HEIGHT}px`,
    bottom: 0,
    left: 0,
    right: 0,
  })
);

export const AllDBelPagesLayout = () => {
  const { data: products } = useGetProductsQuery();
  const [splash, setSplash] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setSplash(false);
    if (!products) return;
    if (location.pathname !== '/') return;

    if (products.length === 0) {
      navigate(`account`);
      return;
    }
    const list = availableApps(products);
    navigate(`app/${list[0].path}/projects`);
  }, [location, navigate, products]);

  return (
    <>
      {splash && <SplashScreen />}
      {!splash && (
        <>
          <Header>
            <TopMenu />
          </Header>
          <Main>
            <Outlet />
          </Main>
        </>
      )}
    </>
  );
};
